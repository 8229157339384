import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Slider from '../../molecules/Slider/Slider';
import { ChildImageSharp } from '../../../types/childImageSharp';
import {
  filterImages,
  filterLightboxImages,
} from '../../../utils/filterImages';

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;

  @media (max-width: 1024px) {
    margin-top: 80px;
    max-width: 700px;
  }
`;

const StyledHeader = styled.header`
  padding-left: 30px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 3.4rem;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 15px 0 0;
  }

  ::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: #000;
  }

  div:last-of-type {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      font-size: 1.8rem;
      font-weight: 700;
      background: transparent;
      border: 0;
      padding: 0;
      color: #ccc;
      margin-right: 30px;
      cursor: pointer;
      transition: color 0.2s ease-in-out;

      :last-of-type {
        margin-right: 0;
      }

      :hover {
        color: #737373;
      }

      &.is-active {
        text-decoration: underline;
        color: #000;
        cursor: default;

        :hover {
          color: #000;
        }
      }
    }

    @media (max-width: 1280px) {
      flex-direction: column;
      justify-content: flex-end;

      button {
        margin: 0 0 25px;

        :last-of-type {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    div:first-of-type {
      max-width: 40%;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 10px;

    ::before {
      display: none;
    }

    div:last-of-type {
      margin: 40px 0 0 0;
      align-items: flex-start;
    }

    div:first-of-type {
      max-width: unset;
    }

    button {
      text-align: left;
      margin: 0 0 20px !important;
    }
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
    }

    p {
      font-size: 1.8rem;
    }

    button {
      font-size: 1.6rem !important;
      margin: 0 0 15px !important;
    }

    div:last-of-type {
      margin: 20px 0 0 0;
    }
  }
`;

const Visualizations: FC<{
  insideImages: ChildImageSharp[];
  insideImagesLightbox: ChildImageSharp[];
  outsideImages: ChildImageSharp[];
  outsideImagesLightbox: ChildImageSharp[];
}> = ({
  outsideImages,
  insideImages,
  insideImagesLightbox,
  outsideImagesLightbox,
}) => {
  const [imagesType, setImagesType] = useState<'all' | 'outside' | 'inside'>(
    'all'
  );

  const getImages = () => {
    switch (imagesType) {
      case 'all':
        return [...filterImages(outsideImages), ...filterImages(insideImages)];
      case 'outside':
        return filterImages(outsideImages);
      case 'inside':
        return filterImages(insideImages);
    }
  };

  const getLightboxImages = () => {
    switch (imagesType) {
      case 'all':
        return [
          ...filterLightboxImages(outsideImagesLightbox),
          ...filterLightboxImages(insideImagesLightbox),
        ];
      case 'outside':
        return filterLightboxImages(outsideImagesLightbox);
      case 'inside':
        return filterLightboxImages(insideImagesLightbox);
    }
  };

  return (
    <StyledWrapper id="visualizations">
      <StyledHeader>
        <div>
          <h2
            data-sal="slide-right"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
          >
            Wizualizacje.
          </h2>
          <p
            data-sal="slide-right"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
            data-sal-delay="200"
          >
            Sprawdź jak możesz mieszkać na osiedlu Nowa Farma
          </p>
        </div>
        <div>
          <button
            onClick={() => setImagesType('all')}
            className={imagesType === 'all' ? 'is-active' : ''}
          >
            Wszystko
          </button>
          <button
            onClick={() => setImagesType('outside')}
            className={imagesType === 'outside' ? 'is-active' : ''}
          >
            Wizualizacje z zewnątrz
          </button>
          <button
            onClick={() => setImagesType('inside')}
            className={imagesType === 'inside' ? 'is-active' : ''}
          >
            Wizualizacje wnętrza
          </button>
        </div>
      </StyledHeader>
      <Slider images={getImages()} lightboxImages={getLightboxImages()} />
    </StyledWrapper>
  );
};

export default Visualizations;
