import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ChangeHandler } from 'react-hook-form/dist/types/form';

const StyledWrapper = styled.label<{ $error?: string }>`
  position: relative;
  display: block;
  transition: color 0.2s ease-in-out;

  input {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 1.8rem;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #ccc;
    transition: border-bottom 0.2s ease-in-out;
  }

  span {
    position: absolute;
    font-size: 1.8rem;
    width: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform-origin: left top;
    transition: transform 0.2s ease-in-out;
  }

  input:not(:placeholder-shown) ~ span {
    transform: translateY(-18px) scale(0.6);
  }

  ${({ $error }) =>
    $error &&
    css`
      color: #e60000;

      input {
        border-bottom: 1px solid #e60000;
        color: #e60000;
      }
    `}

  @media (max-width: 420px) {
    input,
    span {
      font-size: 1.6rem;
    }
  }
`;

const StyledError = styled.div`
  font-size: 1.2rem;
  display: block;
  margin-top: 10px;
  pointer-events: none;
  user-select: none;
`;

const Input = forwardRef<
  HTMLInputElement,
  {
    label: string;
    className?: string;
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    name?: string;
    error?: string;
  }
>(({ label, className, onChange, onBlur, name, error }, ref) => {
  return (
    <StyledWrapper className={className} $error={error}>
      <input
        placeholder=" "
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      />
      <span>{label}</span>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  );
});

export default Input;
