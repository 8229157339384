import React, { FC, useEffect, useState } from 'react';
import YouTube from 'react-youtube';

const YTVideoPlayer: FC<{ videoId: string; className?: string }> = ({
  videoId,
  className,
}) => {
  return <YouTube videoId={videoId} containerClassName={className} />;
};

export default YTVideoPlayer;
