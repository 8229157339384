import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const StyledWrapper = styled.section`
  width: 90%;
  margin-top: 260px;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:last-of-type {
    max-width: 560px;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 35px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-top: 80px;
  }

  @media (max-width: 420px) {
    p {
      font-size: 1.6rem;
      margin-bottom: 15px;
    }
  }
`;

const StyledImageWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 730px;
  height: 585px;
  object-fit: cover;
  margin-right: 70px;

  @media (max-width: 1420px) {
    width: 600px;
  }

  @media (max-width: 1270px) {
    width: 400px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 64vw;
    max-width: 600px;
    max-height: 500px;
    margin-right: 0;
    margin-top: 40px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 60px;
  position: relative;
  max-width: 370px;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 5px;
    background: #000;
    width: 230px;
  }

  h2 {
    font-size: 3.4rem;
  }

  @media (max-width: 1024px) {
    ::after {
      display: none;
    }
  }

  @media (max-width: 420px) {
    margin-bottom: 40px;
    h2 {
      font-size: 2.8rem;
    }
  }
`;

const AboutUs = () => {
  const { file } = useStaticQuery(query);

  return (
    <StyledWrapper id="about_us">
      <StyledImageWrapper
        data-sal="slide-right"
        data-sal-easing="ease-in-out-quart"
        data-sal-duration="1000"
      >
        <StyledImage image={getImage(file.childImageSharp)} alt="" />
      </StyledImageWrapper>

      <div>
        <StyledHeader
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          <h2>O nas</h2>
        </StyledHeader>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          KDM Nieruchomości jest nowoczesną i dynamiczną firmą, która buduje w
          Warszawie nowe osiedla mieszkaniowe w najlepszych lokalizacjach
          miasta.
        </p>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Budujemy nowoczesne osiedla mieszkaniowe w otoczeniu zieleni o
          najwyższych standardach jakości i komfortu.
        </p>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Głównym zadaniem, do którego została powołana spółka KDM
          Nieruchomości, jest świadczenie szerokiego zakresu usług
          deweloperskich.
        </p>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Nasze inwestycje to wyjątkowe, pojedyncze realizacje o niepowtarzalnym
          charakterze, realizowane dla indywidualnych klientów – małych i
          średnich przedsiębiorstw.
        </p>
      </div>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "about_us" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: DOMINANT_COLOR
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default AboutUs;
