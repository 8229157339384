import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { HouseInformation } from '../types/houseInformation';
import { Availability } from '../../../../types/availability';
import { getColor } from '../../../../utils/getColor';
import Button from '../../../atoms/Button/Button';
import { Link } from 'gatsby';
import gsap from 'gsap';
import { getDate } from '../../../../utils/getDate';
import { formatNumber } from '../../../../utils/formatNumber';

const StyledWrapper = styled.div`
  width: 305px;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  flex-direction: column;
  z-index: 20;
  transform: scale(0.6);
  pointer-events: none;
  opacity: 0;
  border: 1px solid #000;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledHeader = styled.div`
  padding: 25px;
  border-bottom: 1px solid #ccc;
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.span`
  line-height: 1;
  font-weight: 700;
`;

const StyledAvailability = styled.span<{ $availability: Availability }>`
  color: ${({ $availability }) => getColor($availability)};
  font-weight: 700;
`;

const StyledTable = styled.div`
  padding: 20px 25px;
  font-size: 1.4rem;
  border-bottom: 1px solid #ccc;

  div {
    margin-bottom: 15px;

    :last-of-type {
      margin-bottom: 0;
    }

    span:first-of-type {
      margin-right: 5px;
    }

    span:last-of-type {
      font-weight: 700;
    }
  }
`;

const StyledBottom = styled.div<{ $availability: Availability }>`
  display: flex;
  padding: 20px 25px 25px;
  flex-direction: column;
  align-items: center;

  div {
    font-size: 1.8rem;

    span:first-of-type {
      color: ${({ $availability }) => getColor($availability)};
      font-weight: 700;
    }

    span:last-of-type {
      font-size: 1.4rem;
    }
  }
`;

const StyledButton = styled(Button)`
  background: transparent;
  color: #000;
  text-decoration: none;
  font-size: 1.4rem;

  svg {
    font-size: 1.2rem;
  }
`;

const getCoords = (name: string) => {
  switch (name) {
    case 'A':
      return { x: '35%', y: '50%' };
    case 'B':
      return { x: '45%', y: '45%' };
    case 'C':
      return { x: '62%', y: '50%' };
    case 'D':
      return { x: '65%', y: '45%' };
  }
};

const Box: FC<{
  currentHouse: HouseInformation;
  isActive: boolean;
  delayHandler: NodeJS.Timeout;
  setDelayHandler: Dispatch<SetStateAction<NodeJS.Timeout>>;
  setIsActive: Dispatch<SetStateAction<boolean>>;
}> = ({
  currentHouse,
  isActive,
  delayHandler,
  setDelayHandler,
  setIsActive,
}) => {
  const wrapper = useRef(null);
  const tl = gsap.timeline();

  const handleMouseOver = () => {
    setIsActive(true);

    if (delayHandler) {
      clearTimeout(delayHandler);
      setDelayHandler(null);
    }
  };
  const handleMouseLeave = () => setIsActive(false);

  useEffect(() => {
    if (isActive) {
      tl.to(wrapper.current, {
        duration: 0.5,
        scale: 1,
        opacity: 1,
        ease: 'back.out(1.7)',
      }).to(wrapper.current, {
        delay: -0.3,
        duration: 0,
        pointerEvents: 'all',
      });
    } else {
      tl.to(wrapper.current, {
        duration: 0.5,
        scale: 0.6,
        opacity: 0,
        ease: 'back.out(1.7)',
      }).to(wrapper.current, {
        delay: -0.3,
        duration: 0,
        pointerEvents: 'none',
      });
    }
  }, [isActive]);

  useEffect(() => {
    const { x, y } = getCoords(currentHouse.number);

    tl.to(wrapper.current, {
      duration: 0,
      pointerEvents: 'none',
    })
      .to(wrapper.current, {
        duration: 1,
        left: x,
        top: y,
        ease: 'expo.out',
      })
      .to(wrapper.current, {
        duration: 0,
        pointerEvents: 'all',
        delay: -0.7,
      });
  }, [currentHouse]);

  return (
    <StyledWrapper
      ref={wrapper}
      className="box"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <StyledHeader>
        <StyledName>Lokal {currentHouse.number.toUpperCase()}</StyledName>
        <StyledAvailability $availability={currentHouse.availability}>
          <span>{currentHouse.availability}</span>
        </StyledAvailability>
      </StyledHeader>

      <StyledTable>
        <div>
          <span>Powierchnia mieszkalna: </span>
          <span>
            {formatNumber(currentHouse.area)} m<sup>2</sup>
          </span>
        </div>
        <div>
          <span>Powierchnia działki: </span>
          <span>
            {formatNumber(currentHouse.parcelArea)} m<sup>2</sup>
          </span>
        </div>
        <div>
          <span>Całkowita ilość pomieszczeń: </span>
          <span>{currentHouse.roomsCount}</span>
        </div>
        <div>
          <span>Garaż: </span>
          <span>{currentHouse.garage}</span>
        </div>
        <div>
          <span>Termin oddania: </span>
          <span>III kwartał 2022</span>
        </div>
      </StyledTable>
      <StyledBottom $availability={currentHouse.availability}>
        <StyledButton forwardedAs={Link} to={`/lokal/${currentHouse.number}`}>
          Zobacz więcej
        </StyledButton>
      </StyledBottom>
    </StyledWrapper>
  );
};

export default Box;
