import React from 'react';
import styled from 'styled-components';
import YTVideoPlayer from '../../atoms/YTVideoPlayer/YTVideoPlayer';

const StyledWrapper = styled.div`
  width: 90%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 260px;
  display: flex;

  @media (max-width: 1400px) {
    align-items: center;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 80px;
  }
`;

const StyledLeftColumn = styled.div`
  flex: 1;

  h2 {
    font-size: 3.2rem;
    margin: 0 0 70px;
    max-width: 520px;
  }

  p {
    line-height: 1.5;
    max-width: 420px;
    margin: 0 0 0 50px;
    font-size: 1.8rem;

    :first-of-type {
      margin: 0 0 30px 50px;
    }
  }

  @media (max-width: 1620px) {
    h2 {
      margin: 0 0 50px;
    }

    p {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 1024px) {
    p {
      width: 100%;
      max-width: 550px;
      margin: 30px 0 0;

      :first-of-type {
        margin: 0;
      }
    }
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
    }
  }
`;

const StyledYTVideo = styled(YTVideoPlayer)`
  width: 860px;
  height: 540px;
  position: relative;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  @media (max-width: 1600px) {
    width: 44.79vw;
    height: 28.125vw;
    max-width: 860px;
    max-height: 540px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    max-width: 550px;
    height: 50vw;
    max-height: 310px;
  }
`;

const StyledVideoWrapper = styled.div`
  margin-left: 40px;
  width: 860px;
  height: 540px;
  position: relative;

  @media (max-width: 1600px) {
    width: 44.79vw;
    height: 28.125vw;
    max-width: 860px;
    max-height: 540px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    max-width: 550px;
    height: 50vw;
    max-height: 310px;
    margin-top: 40px;
  }
`;

const VideoSection = () => {
  return (
    <StyledWrapper>
      <StyledLeftColumn>
        <h2
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Osiedle Nowa Farma w ruchu. Sprawdź animację 3D naszego osiedla!
        </h2>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Osiedle Nowa Farma będzie oferować nowoczesne i komfortowe mieszkania.
          Kupno mieszkań w tym nowym kompleksie mieszkaniowym to dobra
          inwestycja, o której jakości można przekonać się na własne oczy.
        </p>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Zostanie ono wybudowane w najnowszej technologi i zgodnie z
          wymaganiami współczesnych mieszkańców, z poszanowaniem środowiska
          naturalnego i terenów zielonych.
        </p>
      </StyledLeftColumn>
      <StyledVideoWrapper
        data-sal="zoom-out"
        data-sal-easing="ease-in-out-quart"
        data-sal-duration="1000"
      >
        <StyledYTVideo videoId="s5ERYsKDtJE" />
      </StyledVideoWrapper>
    </StyledWrapper>
  );
};

export default VideoSection;
