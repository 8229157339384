import React, { FC, MouseEventHandler } from 'react';
import { Availability } from '../../types/availability';
import { getColor } from '../../utils/getColor';
import { navigate } from 'gatsby-link';
import { getClassNameWithAvailability } from '../../utils/getClassNameWithAvailability';

const InteractiveVisualizationMap: FC<{
  A: Availability;
  B: Availability;
  C: Availability;
  D: Availability;
  isBoxActive: boolean;
  currentActive: 'A' | 'B' | 'C' | 'D';
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}> = ({
  A,
  B,
  C,
  D,
  isBoxActive,
  currentActive,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 5120 2880"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m 979.46619,1920.0917 -16.53377,-109.9984 5.16939,-4.1179 -0.45069,-6.9974 -20.7074,-8.9733 0.15724,-2.8201 129.85614,-53.9223 131.6211,52.8318 c 0,0 41.6259,-7.2021 83.0186,0.7946 113.1231,-51.5776 115.4114,-53.3802 115.4114,-53.3802 l 234.302,89.7274 156.5298,-79.7722 c 0,0 -8.6518,-47.0158 52.3079,-64.9445 5.985,-7.0226 6.2384,-6.492 6.2384,-6.492 l 9.5326,-8.8248 c 0,0 33.769,-41.5766 97.8295,-35.7707 2.0565,-1.2268 2.313,-0.519 2.313,-0.519 0,0 19.6853,-6.7225 49.4177,5.478 3.8908,-1.9813 3.8908,-1.9813 3.8908,-1.9813 l 334.281,-172.4953 -172.5382,-52.3875 -15.4956,-280.5326 -212.6685,-253.3813 -16.9492,5.17298 -0.2219,-4.85163 2.9619,-0.29248 -1.2689,-5.68848 c 0,0 -1.1589,-2.06364 -1.9335,-2.51762 -0.7746,-0.45399 -6.0694,-2.01602 -6.0694,-2.01602 0,0 -0.7475,-0.66687 -1.5268,0 -0.7794,0.66688 -3.3233,5.29431 -3.3233,5.29431 0,0 -0.8312,0.48461 -0.1063,2.96278 0.725,2.47818 -0.9303,5.74245 -0.9303,5.74245 l 4.342,3.97837 -49.9389,11.43592 -1.1801,-20.16361 -4.86,-6.35252 -10.3619,-3.32878 -10.842,1.55414 c 0,0 -3.2138,9.14328 -3.6393,9.79436 -0.4255,0.65108 -0.4255,9.85692 -0.4255,11.09169 0,1.23476 0.3238,8.51788 0.3238,8.51788 l 6.048,7.75401 -380.0655,103.95245 12.7115,-9.04732 3.9903,-11.29695 c 0,0 -8.0126,-16.66429 -11.6481,-8.15381 -3.6354,8.51048 -30.7635,-9.22266 -30.7635,-9.22266 l -25.1108,-4.08552 -18.8337,4.61852 -6.3019,15.89555 -1.8056,14.52239 6.2963,19.661 -187.5335,176.1648 0.9019,11.947 5.6417,0.933 9.0345,75.3339 -826.68347,278.3319 22.5843,109.895 554.00476,250.942 v 0"
        id="path_A"
        aria-={`Zobacz szczegóły domu numer A`}
        className={getClassNameWithAvailability('path', A)}
        opacity="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => navigate('/lokal/A')}
      />
      <g
        id="A"
        className={`parcel ${
          isBoxActive && currentActive === 'A' ? 'is-active' : ''
        }`}
        fill={getColor(A)}
      >
        <path d="m962.68 1816.4v4.7074l-70.056-28.961 0.22979-5.5899z" />
        <path d="m965.15 1827.5 0.0524 5.6041-69.457-29.728-0.62049-5.6316z" />
        <path d="m965.74 1838.7-0.36476 4.3548-68.182-29.977 0.0377-4.5158z" />
        <path d="m968.19 1849.4 0.45685 5.2911-70.516-30.542-0.52162-5.176z" />
        <path d="m968.77 1860.6v4.6447l-67.852-29.958 0.15692-4.3955z" />
        <path d="m970.64 1871.3 0.0214 4.6443-67.79-30.224-0.73368-4.9004z" />
        <path d="m973.79 1882 0.13499 4.5577-68.033-29.396-0.0908-4.419z" />
        <path d="m975.31 1893.3 0.22557 4.2773-64.847-27.597-0.79788-5.1522z" />
        <path d="m977.99 1905-0.0982 4.6012-67.829-29.925-0.17611-4.7346z" />
        <path d="m979.2 1915.1 0.26145 4.9852-67.743-30.356v-4.4371z" />
        <path d="m877 1779.5-0.16402 4.8348-92.938-40.328 0.11421-5.0177z" />
        <path d="m878.61 1791 0.13006 3.5913-93.357-40.409-0.0393-4.3953z" />
        <path d="m879.47 1800.8 0.55605 4.9036-90.588-40.601 0.10617-4.4563z" />
        <path d="m883 1812.9-0.0216 4.0994-90.626-41.516-0.11422-3.8639z" />
        <path d="m884.08 1823.1-0.11421 4.0976-90.048-40.06-0.21827-3.5838z" />
        <path d="m885.02 1833.4-0.0837 4.6935-86.964-39.583-0.20756-4.2945z" />
        <path d="m889.84 1844.9 0.11421 5.6044-88.393-39.643-0.22303-4.5116z" />
        <path d="m889.75 1856.2 0.15926 4.3209-89.235-40.969-0.038-4.0188z" />
        <path d="m892.07 1867.2-0.2154 4.4381-90.732-41.478-0.1216-4.9911z" />
        <path d="m894.56 1878.1-0.11421 4.3675-91.427-41.857 0.44491-3.9942z" />
        <path d="m768.62 1732.2-0.23686 4.7577-90.508-38.513-0.28369-4.9626z" />
        <path d="m769.3 1742.6v5.3325l-87.082-38.586-0.21934-4.3573z" />
        <path d="m771.6 1753.2 0.22843 5.2297-86.739-39.14-0.45382-4.0793z" />
        <path d="m775.12 1765.7 0.45686 4.6814-89.499-40.363-0.60198-4.8433z" />
        <path d="m776.85 1775.3 0.45384 5.2223-88.611-38.704-0.44916-4.5766z" />
        <path d="m778.87 1785.9 0.12942 4.3877-87.082-37.078-0.34215-4.315z" />
        <path d="m780.61 1796.4-0.15321 4.7872-84.257-38.366-0.32681-4.1082z" />
        <path d="m782.56 1807.4 0.1914 4.9753-85.688-39.009 0.0358-4.254z" />
        <path d="m785.17 1817.4 0.18975 6.0061-87.491-40.066 0.0369-4.6348z" />
        <path d="m787.98 1829.4-0.22423 5.0667-88.376-40.557 0.26341-4.9773z" />
        <path d="m580.69 1651.4 0.28737 4.5579 82.281 35.792 0.14165-5.2961z" />
        <path d="m664.07 1697.5-0.58205 4.0368-78.571-34.341-0.12617-4.7238z" />
        <path d="m665.86 1707-0.38055 4.3017-79.772-34.442 0.39368-4.6016z" />
        <path d="m666.98 1717.7 0.0716 3.8194-78.835-33.239-0.46672-4.9674z" />
        <path d="m670.26 1728.4 0.1458 3.8079-80.215-34.636 0.14781-4.3894z" />
        <path d="m672.99 1738.8 0.16152 4.5451-80.488-34.824-0.63564-5.1651z" />
        <path d="m673.9 1748.8-0.26539 5.2407-78.892-35.15-0.32304-4.6711z" />
        <path d="m677.92 1759.7v3.8382l-82.198-35.556-0.54928-5.4106z" />
        <path d="m679.5 1769.9v4.7334l-82.091-37.704-0.16152-3.6211z" />
        <path d="m679.07 1778.6-0.32088 4.4572-80.988-37.151 0.1595-3.6398z" />
        <path d="m564.53 1643.7 0.13093 4.3298-77.818-33.195-0.32101-3.591z" />
        <path d="m567.16 1653.7-0.23359 4.3421-77.183-34.251-0.37466-3.2997z" />
        <path d="m569.39 1663.6v5.6343l-75.283-33.181-0.16152-4.3639z" />
        <path d="m570.28 1673.6 1e-3 4.8164-76.6-33.524-0.31339-4.083z" />
        <path d="m571.96 1684.7v4.4818l-74.735-32.708-0.32305-3.8852z" />
        <path d="m573.03 1694.5-0.18882 4.4523-73.825-34.295-0.33933-3.7808z" />
        <path d="m574.73 1704.1-0.16152 4.7886-75.305-35.5-0.13153-4.3454z" />
        <path d="m575.23 1713.2 0.16152 4.006-74.598-33.712 0.014-4.0272z" />
        <path d="m575.68 1722.8 0.0929 4.5735-73.478-34.341 0.16152-4.0267z" />
        <path d="m576.39 1731.7-0.14526 5.2157-73.327-36.211-0.17035-4.1568z" />
        <path d="m471.7 1603.9-0.33468 4.4172-28.534-13.533 0.31765-4.0717z" />
        <path d="m471.78 1612.6v3.9582l-31.25-14.522 0.13171-3.8328z" />
        <path d="m472.99 1623.5v4.5965l-34.814-17.577 0.15388-3.2282z" />
        <path d="m474.39 1632.4v4.2731l-37.996-17.097 0.48254-3.9077z" />
        <path d="m474.96 1643.1-0.22733 3.1212-40.343-17.194 0.59664-3.8109z" />
        <path d="m478.48 1651.8-0.6144 4.0642-44.517-20.167 0.59069-3.3272z" />
        <path d="m481.63 1662.8-0.73851 3.8136-49.878-22.57 0.71792-3.5855z" />
        <path d="m483.17 1672.7-0.32304 3.8005-53.962-24.628 0.80556-3.8613z" />
        <path d="m485.45 1682-0.30073 4.1368-58.492-25.416 0.48355-3.7983z" />
        <path d="m484.3 1691.1-0.13245 3.7095-58.71-25.651 0.75467-3.7949z" />
        <path d="m2353.7 1457.9-271.36 143.56-7.325 0.011-59.484 30.909s-26.703-12.729-51.731-4.959c-28.077 0.4635-60.748-1.6071-100.41 38.104-2.3249-4.9932-5.6619-4.9339-7.1608-5.1445-1.499-0.2105-7.9479 5.5207 0.2061 11.636-6.414 6.4749-6.2384 6.492-6.2384 6.492s-49.337 17.785-50.118 44.543c-2.1924 16.419-2.1901 20.402-2.1901 20.402l-156.53 79.772-234.3-89.727-115.41 53.38s-54.231-10.902-83.019-0.7946c-131.62-52.832-131.62-52.832-131.62-52.832l-129.86 53.922-0.15724 2.8201 20.707 8.9733 0.45069 6.9974-5.1694 4.1179-69.876-29.822-0.84747-6.4611-8.0583-3.5249-4.9831 3.6373-95.79-40.767v-5.919l-7.4352-3.7325-6.4327 4.0598-92.08-39.818-0.22843-5.9995-5.3132-3.3917-7.7743 2.492-85.119-36.282-0.68528-5.8302-5.6144-2.7525-6.8856 2.3064-81.793-35.388-0.6824-5.9889-4.5617-1.7569-6.0507 2.8345-69.845-38.734 388.95-129.55 1.0296-6.1864 9.9859-3.209 3.6939 4.0002 111.7-36.924 0.21537-5.0546 8.445-2.3576 2.5047 4.5205 85.967-28.824v-4.2659l214.19-70.478-9.0345-75.334-5.6417-0.933-0.9019-11.947 175.51-168.22 21.04-3.1782 0.4569-3.0235 3.9024-0.6745-1.6107-17.365 4.566-1.702-0.7706-7.0322-8.9767-1.6326-0.5393-7.6125 24.587-7.3208 42.249 8.7141 0.7186 7.802-9.8054 2.6321 0.4568 5.0892 5.8855 0.93012 1.3262 3.5342 380.07-103.95-1.2515-22.345 2.391-1.2533-1.1435-8.8522 5.8991-2.4917 13.704 3.2009 1.0147 9.1583 2.7238 0.27314 1.5989 13.442 49.939-11.436-0.3845-7.0104-2.3022-0.23225s4.1498-20.498 13.503-0.51315c-2.8977 0.29248-2.9619 0.29248-2.9619 0.29248l0.2219 4.8516 16.949-5.173 212.67 253.38 15.496 280.53z" />
      </g>

      <path
        d="m 1952.9486,871.6127 18.0687,-5.17571 v 0 l -3.5254,-1.48341 0.1143,-3.90855 c 0,0 -0.1143,-2.04251 -0.2285,-3.05997 -0.1142,-1.01747 -0.7175,-6.15549 -0.7175,-6.15549 l -0.4515,-1.41585 0.1358,-14.0321 1.243,-2.82964 6.5213,-1.89193 12.3841,-0.33745 8.4402,3.37088 1.6904,6.38123 0.5814,6.08076 1.968,5.31423 0.4991,-8.59754 2.2255,-2.39848 3.3186,-0.34264 4.805,1.45282 0.7995,2.93411 2.2203,5.27124 6.5161,2.69594 h 3.9512 l 311.0032,-85.89339 -5.8079,-8.73221 -2.5876,-8.21242 v -12.20749 l 4.0876,-4.08759 15.7486,1.08337 20.696,-1.61604 18.5086,6.77817 9.5304,3.24899 0.011,6.77322 -3.7356,10.19246 -1.6552,9.73609 -0.2284,10.68724 0.483,17.30566 166.5152,166.91602 0.6565,13.94423 -3.5898,2.6097 -0.066,50.21864 39.9655,-17.263 17.5746,1.1421 2.878,-3.7661 51.02,9.253 -0.4569,3.507 98.4939,19.151 -92.7605,40.4317 292.4724,57.0442 -202.2175,103.2242 -58.4207,-13.2418 -23.0912,12.049 -0.034,7.4667 10.3257,5.1429 0.074,6.9257 -5.4926,1.4815 -0.6106,18.3174 -329.1465,174.8188 -172.5382,-52.3875 -15.4956,-280.5326 -212.6685,-253.381"
        id="path_B"
        aria-label={`Zobacz szczegóły domu numer B`}
        className={getClassNameWithAvailability('path', B)}
        opacity="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => navigate('/lokal/B')}
      />
      <g
        id="B"
        className={`parcel ${
          isBoxActive && currentActive === 'B' ? 'is-active' : ''
        }`}
        fill={getColor(B)}
      >
        <path d="m2962.3 1141.7-216.16-38.79-76.308-18.254-17.744 7.9749-99.61-17.835 0.7064-92.815 3.5898-2.6097-0.6565-13.944-173.12-174.3-0.1465-36.549-5.4672-0.69258 0.4568-4.0956 7.7453-2.6843-0.3916-6.5418-39.205-5.1621-13.298 4.538 0.4273 6.2761 3.1675 1.4763 1.1358 5.7075-2.8734-0.18846v14.347l-324.82 90.142-0.4074-4.94 3.7563-2.0047s-9.7753-19.034-13.868 1.6805c3.11 0.30742 2.7211 0.20212 2.7211 0.20212l0.8378 6.0537-7.4196 1.6668-1.3761-13.556-2.4296-0.33689 0.042-9.9228-15.52-2.7248-6.1412 3.2532 0.4492 3.7015 2.8855 0.44923-0.6511 3.9378-3.1204 1.3587 1.5666 19.885-18.069 5.176 212.67 253.38 15.496 280.53 172.54 52.388 165.09-81.42 164.06-93.398 0.6108-18.317 5.4926-1.4815-0.074-6.9257-10.326-5.1429 0.034-7.4667 23.091-12.049 58.421 13.242z" />
        <path d="m2762.6 1044.3-98.494-19.151 0.4569-3.507-51.02-9.253-2.878 3.7661-17.575-1.1421-39.966 17.263-0.64 42.596 99.61 17.835z" />
      </g>

      <path
        d="m 1756.0526,2277.0173 383.6262,-268.9108 -290.1859,-96.429 501.6415,-285.9668 -4.4716,-128.82 -4.3664,-0.3928 0.4179,-17.7409 340.0831,-195.6617 0.6108,-18.3174 5.4926,-1.4815 -0.074,-6.9257 -10.3259,-5.1429 0.034,-7.4667 23.0916,-12.049 58.4205,13.2418 239.7926,-121.1704 -8.8086,-8.3069 4.6262,-39.8743 3.0412,-0.5839 -0.088,-8.6783 4.366,-3.0551 20.4501,3.3846 -0.052,11.7971 2.1827,-0.079 -0.3295,31.3828 61.7641,-32.5135 1.2965,-13.6818 314.4393,303.1418 -22.4887,335.7853 254.733,91.3721 -449.1422,546.0702 19.9624,34.1969 -6.1226,18.8931 6.597,53.0988 -298.1233,361.0169 -1152.0906,-530.133 11.5247,-7.1472 v 0"
        id="path_C"
        className={getClassNameWithAvailability('path', C)}
        aria-label={`Zobacz szczegóły domu numer C`}
        opacity="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => navigate('/lokal/C')}
      />
      <g
        id="C"
        className={`parcel ${
          isBoxActive && currentActive === 'C' ? 'is-active' : ''
        }`}
        fill={getColor(C)}
      >
        <path d="m1756.1 2277 3.0714-3.3531 88.064 40.204-0.095 4.8677z" />
        <path d="m1847 2301.7-0.1263 4.0219-79.33-35.814 2.5864-2.7443z" />
        <path d="m1845.5 2294.2-13.437-7.4414 0.1735-3.8933 13.264 5.9561v5.3786" />
        <path d="m1858.2 2213.3-0.4888-5.552 163.41 71.076-0.4843 4.6158z" />
        <path d="m2021.5 2296.6-0.319-5.56-161.59-70.702 0.018 5.3731z" />
        <path d="m2021.6 2309.8 0.099-5.2034-161.63-72.15 0.3504 4.9975z" />
        <path d="m2023.5 2317.1-0.1226 5.4041-162.39-71.753-0.4831-4.493z" />
        <path d="m2023.9 2330.8-0.151 5.3437-162.88-72.564 0.012-4.8145z" />
        <path d="m2023.6 2343.6 0.018 5.404-160.33-72.332 0.1697-4.964z" />
        <path d="m2024.8 2356.8 0.1227 5.6009-162.83-73.944 0.2622-5.2238z" />
        <path d="m2025.5 2369.5-0.1703 5.8457-163.65-73.774 0.1334-5.6276z" />
        <path d="m2027.6 2383-0.248 5.5929-162.94-74.062 0.5862-5.2883z" />
        <path d="m2028.1 2395.7-0.5593 6.36-164.38-75.601 0.4797-6.5856z" />
        <path d="m1834.2 2214.7 4.0799-3.2158-0.1687 5.1391z" />
        <path d="m1832.3 2220.5-4.6891 3.3053 11.553 5.9685 0.408-5.6232z" />
        <path d="m1828.7 2231.4-0.114 5.923 11.589 5.2549 0.2258-6.0907-11.449-5.0231" />
        <path d="m1842.4 2249.7-0.3658 5.7663-13.402-6.337-0.1507-5.3658z" />
        <path d="m1829.8 2257.1-0.2506 4.9108 13.146 6.0812 0.5572-5.7892z" />
        <path d="m1844.3 2275.8-0.6699 4.2849-13.825-5.5768 0.077-4.8295z" />
        <path d="m1845.5 2288.8-0.7238 4.4204-11.724-5.3744 0.1006-4.1778z" />
        <path d="m2040.7 2286.5-0.4858 5.3898 176.12 76.073 0.3231-5.5075z" />
        <path d="m2217.1 2376.6-0.3565 5.9852-175.82-77.764v-5.1134z" />
        <path d="m2218.5 2390.1-0.3409 6.1971-176.91-77.8v-5.6765z" />
        <path d="m2218.6 2402.9-0.3076 6.8729-175.6-78.164-0.1961-5.1022z" />
        <path d="m2219.4 2416.5-0.1479 6.4539-176.66-78.184 0.089-5.7921z" />
        <path d="m2220.1 2430.9-0.3211 6.3761-176.75-79.878 0.1615-5.2007z" />
        <path d="m2220.3 2444.2-0.046 5.9637-176.19-79.594-0.058-4.9672z" />
        <path d="m2220.6 2457.5-0.1681 5.5505-174.17-78.489-5e-4 -5.4528z" />
        <path d="m2221.4 2471.2-0.2756 5.7067-174.86-79.886 0.2089-5.0808z" />
        <path d="m2221.8 2483.6-0.3831 5.6748-175.41-79.86 0.1096-5.701z" />
        <path d="m2238.5 2372.5-0.055 4.4085 188.7 83.66 0.3753-6.6006z" />
        <path d="m2428.3 2468.7-0.3677 6.2077-189.58-83.283 0.2745-5.7058z" />
        <path d="m2428.8 2482.4-0.4299 6.3815-190.97-83.752 0.3231-6.175z" />
        <path d="m2428.4 2496.4-0.6272 5.6127-189.58-83.416 0.1494-5.5022z" />
        <path d="m2427.4 2510.2-0.4739 5.7666-190.24-84.681 0.3254-5.2785z" />
        <path d="m2428.5 2523.8-0.1942 6.2963-189.98-84.518-0.2941-6.2122z" />
        <path d="m2428.7 2536.7-0.1751 6.587-188.74-84.69-0.3065-4.6743z" />
        <path d="m2428 2551.3-0.3685 6.1429-188.46-85.744v-5.1173z" />
        <path d="m2241 2480.4-0.2444 4.6269 187.48 85.821 0.1667-6.5042z" />
        <path d="m2241.4 2493.4-0.2997 4.4185 188.25 86.586 0.2284-5.2632z" />
        <path d="m2448.3 2463.6-0.3254 5.2164 207.67 91.193 0.5052-6.1625z" />
        <path d="m2448.8 2477.9-0.1094 5.4262 207.09 90.804 0.1625-5.8729z" />
        <path d="m2655.1 2581.9-0.1549 5.8211-207.1-90.916 0.4568-5.3033z" />
        <path d="m2655.1 2597.6-0.3917 5.1616-205.84-91.873 0.4569-5.1065z" />
        <path d="m2655.7 2610.9-0.3628 5.6687-207.55-91.6 0.053-6.0759z" />
        <path d="m2654.6 2625.6-0.08 5.9937-207.37-93.385-0.1041-5.6523z" />
        <path d="m2654.6 2639.5-0.5695 6.2564-206.92-94.826 0.2027-6.399z" />
        <path d="m2654.2 2653.1-0.1418 5.8717-207.6-95.797 0.3066-5.2728z" />
        <path d="m2653.5 2668v6.3338l-208.53-97.494 0.3955-5.8151z" />
        <path d="m2654.3 2682.5-0.6275 5.6818-209.84-98.762 0.5786-5.5326z" />
        <path d="m2678.3 2564-0.3706 5.329 224.43 96.899 0.4846-5.9416z" />
        <path d="m2678.3 2578-0.4104 5.4184 223.39 97.928 0.5289-6.8002z" />
        <path d="m2902.2 2690.5-0.3845 6.6872-224.63-98.738 0.023-5.7971z" />
        <path d="m2677.5 2605.2-1.1215 6.2631 224.28 99.924 0.4548-6.3908z" />
        <path d="m2676.7 2621.3-0.7895 4.9673 224.62 101.35 0.4889-8.7113z" />
        <path d="m2677.2 2635.4-0.861 5.9193 223.01 100.59 0.4626-7.9496z" />
        <path d="m2677.7 2650.4-1.239 5.2994 223.2 99.83 0.4777-8.3555z" />
        <path d="m2678.4 2663.6-1.202 6.1778 221.36 101.34 0.5632-8.1648z" />
        <path d="m2676.8 2678.3-1.0378 5.6558 221.44 99.974 0.596-7.2859z" />
        <path d="m2895.7 2792.4-0.6644 5.4831-220.75-102.51 0.5683-4.94z" />
        <path d="m3021.2 2538.3 0.2339 4.6173-94.91 106.86-1.8218-3.9262z" />
        <path d="m3022.4 2551.8 0.4569 4.8364-96.585 107.91-0.6853-5.0514z" />
        <path d="m2926.2 2671.6v6.211l97.744-112.1-0.4416-4.2082z" />
        <path d="m2921.6 2774.4-0.8946 5.6385 102.88-119.99-0.7258-3.982z" />
        <path d="m2921.6 2758.5-0.5051 5.5651 102.08-117.54-0.4541-3.5867z" />
        <path d="m3021.7 2630.2 0.5114 3.4912-100.09 115.3-0.2284-5.0451z" />
        <path d="m2922.5 2728.7-0.2285 7.3492 98.914-116.34-0.4374-4.4062z" />
        <path d="m3022.2 2603.4-0.2285 5.1289-98.047 111.31 0.074-5.8836z" />
        <path d="m3023.7 2590.4-0.2045 5.1878-99.447 110.26 0.272-5.9118z" />
        <path d="m2925.9 2685.5-0.013 4.686 99.077-112.55-0.3704-3.2136z" />
        <path d="m3039 2544.2-0.7172 6.2689 92.595-103.29-0.3046-4.4182z" />
        <path d="m3038.6 2530.9 0.012 6.3784 92.263-104.76 0.03-4.651z" />
        <path d="m3038.7 2517.4-0.087 7.0567 92.222-103.2-0.2984-4.4076z" />
        <path d="m3034.3 2640.6 0.9561 4.7497 98.415-115.75-1.6609-4.7307z" />
        <path d="m3035.1 2629.2 0.019 5.5224 97.647-116.52-1.5687-3.0309z" />
        <path d="m3035 2609.7 0.033 7.6229 91.197-103.05-1.9882-3.5967z" />
        <path d="m3035 2560.6 0.4734 5.3257 97.71-108.21-1.3375-3.9995z" />
        <path d="m3035.6 2573.7 0.091 4.6543 96.759-106.9-0.2629-4.4222z" />
        <path d="m3035.4 2586v5.4323l96.328-106.62-0.9297-4.522z" />
        <path d="m3034.5 2602.4 0.1136 4.9535 96.802-108.41 0.01-5.0182z" />
        <path d="m3035 2614.1 0.3398 4.374 95.91-108.77-0.617-3.9354z" />
        <path d="m1857 2200 282.64-191.86-290.19-96.429 501.64-285.97-4.4716-128.82-4.3664-0.3928 0.4179-17.741 315.87-183.25 24.216-12.415 0.6108-18.317 5.4926-1.4815-0.074-6.9257-10.326-5.1429 0.034-7.4667 23.091-12.049 58.421 13.242 239.79-121.17-8.8086-8.3069 4.6262-39.874 3.0412-0.5839-0.088-8.6783 4.366-3.0551 20.45 3.3846-0.052 11.797 2.1827-0.079-0.3295 31.383 61.764-32.514 1.2965-13.682 314.44 303.14-22.489 335.79 254.73 91.372-487.72 593.66-2.1765-7.6162-14.712 10.604 0.2227 15.633-91.54 103.16-9.4088-6.0415-8.5398 7.5402 0.3129 18.219-102.73 115.28-6.6487-3.8098-10.058 10.325-224.03-97.219 0.349-12.685-14.892-6.7276-8.1297 9.8859-206.71-89.418 0.9691-12.302-14.028-7.4941-9.1865 9.899-188.72-81.45 0.5153-10.743-13.467-7.5806-7.9142 8.445-176.3-75.352-0.6461-11.907-9.6217-6.6422-9.4034 6.1947v2.9319z" />
        <path d="m3143.3 2512.8 0.1615 5.0742 62.794-71.777-1.279-3.7689z" />
        <path d="m3143.8 2499.4-0.2474 6.4304 59.766-68.36-1.5532-3.0929z" />
        <path d="m3143.4 2486.9v5.2831l56.936-62.128-1.8291-3.6899z" />
        <path d="m3144.3 2475v5.0109l50.658-56.57-1.2263-3.0065z" />
        <path d="m3144.5 2462.1-0.083 5.0559 44.88-49.228-1.0293-3.076z" />
        <path d="m3146.3 2447.9-0.1861 4.7037 53.561-59.527-1.8527-1.839z" />
        <path d="m3148.4 2394.6 0.3671 4.9466 42.526-46.977-2.6934-4.1158z" />
        <path d="m3148.1 2406.9-0.2538 5.8086 49.204-52.856-1.8871-3.5948z" />
        <path d="m3148.1 2419.5v5.8009l52.6-59.099-1.3415-2.45z" />
        <path d="m3147.8 2432.4-0.1615 5.2391 58.12-63.511-1.9337-2.0734z" />
      </g>

      <path
        d="m 3634.9719,1793.8744 395.493,-445.4184 -226.9396,-45.8828 7.6364,-64.206 -133.6027,-24.2 4.4672,-44.3684 3.6547,-2.6127 1.6043,-17.5692 -213.6379,-200.31352 4.1208,-51.31314 -4.437,0.16751 -0.1142,-5.05455 9.3907,-3.48182 0.6311,-8.41894 -53.7487,-7.5715 -10.8627,6.56313 0.438,7.14918 5.8783,1.21175 -0.3426,5.84035 -4.4662,2.09104 0.2059,10.99088 -253.5927,129.85043 -0.9724,-4.4698 -5.2505,-2.1229 -5.1782,1.081 -1.4491,3.0148 -0.9007,4.4387 2.1183,4.2614 -37.3401,18.8726 0.8507,-29.2641 -1.7851,-1.9572 0.5403,-10.0341 -20.9339,-3.6074 -5.3311,4.5351 -2.8279,41.4994 314.4393,303.1418 -22.4887,335.7853 254.733,91.3721 v 0 0"
        id="path_D"
        className={getClassNameWithAvailability('path', D)}
        aria-label={`Zobacz szczegóły domu numer D`}
        opacity="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => navigate('/lokal/D')}
      />
      <g
        id="D"
        className={`parcel ${
          isBoxActive && currentActive === 'D' ? 'is-active' : ''
        }`}
        fill={getColor(D)}
      >
        <path d="m3088.3 1063.6 2.8279-41.499 5.3311-4.5351 20.934 3.6074-0.5403 10.034 1.7851 1.9572-0.8507 29.264 37.34-18.873s-3.9549-10.616 3.8444-11.595c7.7993-0.9793 7.7882 5.3915 7.7882 5.3915l253.59-129.85-0.2059-10.991 4.4662-2.091 0.3426-5.8404-5.8783-1.2118-0.438-7.1492 10.863-6.5631 53.749 7.5715-0.6311 8.4189-9.3907 3.4818 0.1142 5.0546 4.437-0.16751-4.1208 51.313 213.64 200.31-1.6043 17.569-3.6547 2.6127-4.4672 44.368 133.6 24.2-7.6364 64.206 226.94 45.883-395.49 445.42-254.73-91.372 22.489-335.79z" />
      </g>
    </svg>
  );
};

export default InteractiveVisualizationMap;
