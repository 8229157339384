import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import { checkedIcon } from '../../../assets/icons';
import { ChangeHandler } from 'react-hook-form/dist/types/form';

const StyledWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;

  div:first-of-type {
    margin-right: 15px;
    position: relative;

    span {
      width: 26px;
      height: 26px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        font-size: 2.2rem;
      }
    }

    input:checked + span {
      background: #000;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
  }
`;

const StyledCheckbox = styled.span`
  pointer-events: none;
`;

const StyledInput = styled.input``;

const StyledError = styled.span`
  font-size: 1.2rem;
  color: #e60000;
  pointer-events: none;
  margin-top: 10px;
  display: block;
`;

const Checkbox = forwardRef<
  HTMLInputElement,
  {
    id?: string;
    className?: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked?: boolean;
    onBlur?: ChangeHandler;
    name?: string;
    error?: string;
  }
>(
  (
    { label, id, className, onChange, checked, onBlur, name, error, ...props },
    ref
  ) => {
    return (
      <div className={className} {...props}>
        <StyledWrapper>
          <div>
            <StyledInput
              type="checkbox"
              id={id}
              onChange={onChange}
              checked={checked}
              ref={ref}
              name={name}
              onBlur={onBlur}
            />
            <StyledCheckbox>
              <Icon icon={checkedIcon} />
            </StyledCheckbox>
          </div>
          <div>{label}</div>
        </StyledWrapper>
        {error && <StyledError>{error}</StyledError>}
      </div>
    );
  }
);

export default Checkbox;
