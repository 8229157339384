import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from '@iconify/react';
import { arrowLeftIcon, arrowRightIcon } from '../../../assets/icons';
import Lightbox from '../../atoms/Lightbox/Lightbox';
import { getImage, ImageDataLike, GatsbyImage } from 'gatsby-plugin-image';
import { id } from '../../../utils/id';

SwiperCore.use([Navigation]);

const StyledWrapper = styled.div`
  position: relative;

  .swiper-container {
    max-width: 100%;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 600px;
  height: 342px;
  object-fit: cover;

  &.is-active {
    cursor: grab;
  }

  @media (max-width: 1024px) {
    width: 89vw;
    height: 47vw;
    max-width: 600px;
    max-height: 342px;
  }
`;

const StyledNavigation = styled.div<{ $position: 'top' | 'bottom' }>`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;

  button {
    background: transparent;
    border: 0;
    margin-right: 50px;
    color: #000;
    font-size: 2.4rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    :last-of-type {
      margin-right: 0;
    }

    :hover {
      color: #737373;
    }

    :active svg {
      transform: scale(0.9) !important;
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 420px) {
    margin-top: 30px;
    button {
      font-size: 1.8rem;
    }
  }

  ${({ $position }) =>
    $position === 'top' &&
    css`
      @media (min-width: 1025px) {
        position: absolute;
        right: 10%;
        bottom: 113%;
      }
    `}
`;

const Slider: FC<{
  images: ImageDataLike[];
  navigation?: 'top' | 'bottom';
  lightboxImages: string[];
}> = ({ images, navigation = 'bottom', lightboxImages }) => {
  const [swiper, setSwiper] = useState<SwiperCore>(null);
  const [isLightboxActive, setLightboxActive] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const nextButtonId = id();
  const prevButtonId = id();

  const onImageClick = (index: number) => {
    setCurrentImage(index);
    setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentImage(swiper.realIndex);
  };

  const slideTo = (index: number) => swiper?.slideTo(index, 1000);

  useEffect(() => {
    setCurrentImage(0);
    slideTo(0);
  }, [images]);

  return (
    <StyledWrapper>
      <Swiper
        grabCursor
        updateOnWindowResize
        slidesPerView="auto"
        // breakpoints={{
        //   1025: {
        //     slidesPerView: 'auto',
        //   },
        // }}
        spaceBetween={25}
        navigation={{
          nextEl: `#${nextButtonId}`,
          prevEl: `#${prevButtonId}`,
        }}
        onSwiper={(swiper) => setSwiper(swiper)}
        loop
        onSlideChange={onSlideChange}
      >
        {images.map((src, i) => (
          <SwiperSlide key={i}>
            <div
              data-sal="fade"
              data-sal-easing="ease-in-out-quart"
              data-sal-duration="500"
            >
              <StyledImage
                image={getImage(src)}
                alt=""
                onClick={() => onImageClick(i)}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledNavigation $position={navigation}>
        <button id={prevButtonId} aria-label="Poprzednie zdjęcie">
          <Icon icon={arrowLeftIcon} />
        </button>
        <button id={nextButtonId} aria-label="Następne zdjęcie">
          <Icon icon={arrowRightIcon} />
        </button>
      </StyledNavigation>

      <Lightbox
        currentImageIndex={currentImage}
        setCurrentImageIndex={setCurrentImage}
        isOpen={isLightboxActive}
        setOpen={setLightboxActive}
        images={lightboxImages.map((lightboxImage) => ({
          src: lightboxImage,
          loading: 'lazy',
          alt: '',
        }))}
        slideTo={slideTo}
      />
    </StyledWrapper>
  );
};

export default Slider;
