import { Availability } from '../types/availability';

export const getClassNameWithAvailability = (
  prefix: string,
  availability: Availability
) =>
  availability === Availability.AVAILABLE
    ? `${prefix}_available`
    : availability === Availability.RESERVED
    ? `${prefix}_reserved`
    : `${prefix}_unavailable`;
