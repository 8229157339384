import React from 'react';
import styled from 'styled-components';
import { maxWidth } from '../../../theme/repeatedStyles';
import GoogleMap from '../../atoms/GoogleMap/GoogleMap';
import { GOOGLE_MAPS_CENTER, GOOGLE_MAPS_ZOOM } from '../../../config/config';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.section`
  margin-top: 260px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 80px;
  }
`;

const StyledHeader = styled.header`
  ${maxWidth};
  padding-left: 255px;
  position: relative;
  margin-bottom: 110px;

  h2 {
    font-size: 3.4rem;
    font-weight: 700;
    max-width: 700px;
    position: relative;

    ::after {
      content: '';
      position: absolute;
      left: -255px;
      top: 50%;
      transform: translateY(-50%);
      width: 220px;
      height: 5px;
      background: #000;
    }
  }

  p {
    font-size: 1.8rem;
    margin-left: auto;
    max-width: 500px;
  }

  @media (max-width: 1400px) {
    padding-left: 110px;

    h2::after {
      left: -110px;
      width: 75px;
    }
  }

  @media (max-width: 1024px) {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    h2 {
      margin: 0 0 15px;
      ::after {
        display: none;
      }
    }

    p {
      margin-left: 0;
    }
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
    }

    p {
      font-size: 1.6rem;
    }
  }
`;

const StyledGoogleMap = styled(GoogleMap)`
  iframe {
    width: 100%;
    height: 580px;
  }
`;

const Localization = () => {
  const {
    datoCmsSetting,
  }: { datoCmsSetting: { coords: { latitude: number; longitude: number } } } =
    useStaticQuery(query);

  return (
    <StyledWrapper id="localization">
      <StyledHeader>
        <h2
          data-sal="slide-right"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Lokalizacja osiedla
        </h2>
        <p
          data-sal="slide-left"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
          data-sal-delay="200"
        >
          Osiedle zostanie wybudowane w świetnej lokalizacji przy ulicy
          Farbiarskiej, gmina Ursynów, Pyry. Niedaleko centrum Warszawy.
        </p>
      </StyledHeader>
      <div
        data-sal="fade"
        data-sal-easing="ease-in-out-quart"
        data-sal-duration="1000"
      >
        <StyledGoogleMap
          center={{
            lat: datoCmsSetting.coords.latitude,
            lng: datoCmsSetting.coords.longitude,
          }}
          zoom={GOOGLE_MAPS_ZOOM}
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        />
      </div>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      coords {
        latitude
        longitude
      }
    }
  }
`;

export default Localization;
