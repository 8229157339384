import React, { FC } from 'react';
import styled from 'styled-components';
import BasicTemplate from '../templates/BasicTemplate';
import Hero from '../components/molecules/Hero/Hero';
import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import AboutEstate from '../components/molecules/AboutEstate/AboutEstate';
import Visualizations from '../components/organisms/Visualizations/Visualizations';
import VideoSection from '../components/molecules/VideoSection/VideoSection';
import Localization from '../components/molecules/Localization/Localization';
import Standards from '../components/molecules/Standards/Standards';
import InteractiveVisualizationSection from '../components/molecules/InteractiveVisualizationSection/InteractiveVisualizationSection';
import BuildGallery from '../components/organisms/BuildGallery/BuildGallery';
import AboutUs from '../components/molecules/AboutUs/AboutUs';
import OurInvestments from '../components/molecules/OurInvestments/OurInvestments';
import Contact from '../components/molecules/Contact/Contact';
import Footer from '../components/molecules/Footer/Footer';
import { ChildImageSharp } from '../types/childImageSharp';

const StyledMain = styled.main`
  margin-top: 120px;

  @media (max-width: 1024px) {
    margin-top: 60px;
  }
`;

type Nodes = {
  nodes: ChildImageSharp[];
};

const Index: FC<{
  data: {
    heroQuery: ChildImageSharp;
    aboutEstateImage: ChildImageSharp;
    visualizationsOutsideImages: Nodes;
    visualizationsInsideImages: Nodes;
    visualizationsOutsideImagesLightbox: Nodes;
    visualizationsInsideImagesLightbox: Nodes;
    buildImages: { images: ImageDataLike[] };
    buildImagesLightbox: { images: ImageDataLike[] };
  };
  location: {
    state: {
      scrollTo: string;
    };
  };
}> = ({ data, location }) => (
  <BasicTemplate
    header={() => <Hero image={data.heroQuery.childImageSharp} />}
    scrollTo={location?.state?.scrollTo}
  >
    <StyledMain>
      <AboutEstate image={data.aboutEstateImage.childImageSharp} />
      <Visualizations
        outsideImages={data.visualizationsOutsideImages.nodes}
        outsideImagesLightbox={data.visualizationsOutsideImagesLightbox.nodes}
        insideImages={data.visualizationsInsideImages.nodes}
        insideImagesLightbox={data.visualizationsInsideImagesLightbox.nodes}
      />
      <VideoSection />
      <Localization />
      {/*<Standards />*/}
      <InteractiveVisualizationSection />
      {data.buildImages.images.length &&
        data.buildImagesLightbox.images.length && (
          <BuildGallery
            images={data.buildImages.images}
            lightboxImages={data.buildImagesLightbox.images}
          />
        )}
      <AboutUs />
      {/*<OurInvestments />*/}
      <Contact />
    </StyledMain>
    <Footer />
  </BasicTemplate>
);

export const query = graphql`
  {
    heroQuery: file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    aboutEstateImage: file(name: { eq: "2_4K" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    visualizationsOutsideImages: allFile(
      filter: { name: { regex: "/gallery_outside/" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80, width: 700, layout: CONSTRAINED)
        }
      }
    }

    visualizationsOutsideImagesLightbox: allFile(
      filter: { name: { regex: "/gallery_outside/" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90, formats: JPG, layout: FIXED)
        }
      }
    }

    visualizationsInsideImages: allFile(
      filter: { name: { regex: "/gallery_inside/" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80, width: 600, layout: CONSTRAINED)
        }
      }
    }

    visualizationsInsideImagesLightbox: allFile(
      filter: { name: { regex: "/gallery_inside/" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90, formats: JPG, layout: FIXED)
        }
      }
    }

    buildImages: datoCmsBuildGallery {
      images {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    buildImagesLightbox: datoCmsBuildGallery {
      images {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`;

export default Index;
