import React, { FC } from 'react';
import styled from 'styled-components';
import { maxWidth } from '../../../theme/repeatedStyles';
import Logo from '../../atoms/Logo/Logo';
import IconBlock from '../../atoms/IconBlock/IconBlock';
import {
  airplaneIcon,
  knowledgeIcon,
  parkIcon,
  residentalIcon,
  skyscrapperIcon,
} from '../../../assets/icons';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

const StyledWrapper = styled.section`
  ${maxWidth}
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 759px;
  height: 426px;
  object-fit: cover;

  @media (max-width: 1440px) {
    width: 630px;
    height: 350px;
  }

  @media (max-width: 1200px) {
    width: 530px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 530px;
    height: auto;
  }
`;

const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  flex: 1;
  position: relative;
  justify-content: flex-end;

  img {
    width: 180px;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  h2 {
    font-size: 3.4rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
    max-width: 422px;
  }

  p {
    max-width: 422px;
  }

  p:first-of-type {
    font-size: 1.8rem;
    margin-top: 15px;
    max-width: 85%;
  }

  p:last-of-type {
    font-size: 1.8rem;
    font-weight: 600;

    span {
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    text-align: center;
    margin-left: 0;

    h2 {
      text-align: center;
    }

    p:first-of-type {
      max-width: 100%;

      span {
        position: relative;
        padding-bottom: 25px;
      }

      span::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background: #000;
      }
    }

    p:last-of-type {
      margin-top: 25px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
    }

    p:first-of-type,
    p:last-of-type {
      font-size: 1.6rem;
    }
  }
`;

const StyledParagraphWrapper = styled.div`
  display: flex;
  margin-top: 90px;
  font-size: 1.8rem;
  justify-content: space-between;

  p {
    max-width: 550px;
    line-height: 1.5;

    :last-of-type {
      margin-top: 70px;
      margin-left: 50px;
    }

    span {
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    p {
      margin: 0;

      :last-of-type {
        margin: 35px 0 0 0;
      }
    }
  }

  @media (max-width: 420px) {
    font-size: 1.6rem;
  }
`;

const IconBlocksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 120px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    max-width: 450px;
    margin: 50px auto 0 auto;
    justify-content: space-around;
  }
`;

const StyledIconBlock = styled(IconBlock)`
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledLogo = styled(Logo)`
  @media (max-width: 1294px) {
    display: none;
  } ;
`;

const AboutEstate: FC<{ image: ImageDataLike }> = ({ image }) => {
  return (
    <StyledWrapper id="about_estate">
      <StyledInnerWrapper>
        <div
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-easing="ease-in-out-quart"
        >
          <StyledImage image={getImage(image)} alt="" />
        </div>
        <StyledRightColumn>
          <StyledLogo
            data-sal="zoom-out"
            data-sal-duration="1000"
            data-sal-easing="ease-in-out-quart"
          />
          <h2
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-delay="300"
            data-sal-duration="500"
          >
            Nowe osiedle w Warszawie!
          </h2>
          <p
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-delay="600"
            data-sal-duration="500"
          >
            <span>Warszawa, ul. Farbiarska</span>
          </p>
          <p
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-delay="900"
            data-sal-duration="500"
          >
            Jest to inwestycja deweloperska powstająca na{' '}
            <span>południowych obrzeżach Warszawy</span> w sąsiedztwie{' '}
            <span>Lasu Kabackiego</span> i domów jednorodzinnych otoczonych
            zielenią.
          </p>
        </StyledRightColumn>
      </StyledInnerWrapper>
      <StyledParagraphWrapper>
        <p
          data-sal="flip-right"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="1000"
        >
          Nowa Farma to <span>wyjątkowe</span> osiedle, które będzie atrakcyjne
          zarówno dla rodzin z dziećmi, jak i młodych ludzi, którzy chcą
          mieszkać w mieście, ale jednocześnie cieszyć się prywatnością.
        </p>
        <p
          data-sal="flip-left"
          data-sal-easing="ease-in-out-quart"
          data-sal-delay="300"
          data-sal-duration="1000"
        >
          Osiedle składa się z dwóch budynów w zabudowie bliźniaczej. Dogodna
          lokalizacja osiedla zapewnia łatwy dostęp do centum miasta za pomocą{' '}
          <span>komunikacji miejskiej i samochodu.</span>
        </p>
      </StyledParagraphWrapper>
      <IconBlocksWrapper>
        <div
          data-sal="zoom-in"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          <StyledIconBlock icon={airplaneIcon}>
            10 min
            <br />
            Lotnisko Chopina
          </StyledIconBlock>
        </div>
        <div
          data-sal="zoom-in"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
          data-sal-delay="200"
        >
          <StyledIconBlock icon={skyscrapperIcon}>
            20 min
            <br />
            Centrum
          </StyledIconBlock>
        </div>
        <div
          data-sal="zoom-in"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
          data-sal-delay="400"
        >
          <StyledIconBlock
            icon={parkIcon}
            data-sal="zoom-in"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
            data-sal-delay="600"
          >
            Domy otoczone zielenią, bliskość lasu Kabackiego
          </StyledIconBlock>
        </div>
        <div
          data-sal="zoom-in"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
          data-sal-delay="800"
        >
          <StyledIconBlock icon={residentalIcon}>
            Kameralna ulica w sąsiedztwie domów jednorodzoinnych
          </StyledIconBlock>
        </div>
        <div
          data-sal="zoom-in"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
          data-sal-delay="1000"
        >
          <StyledIconBlock icon={knowledgeIcon}>
            5 min <br /> Szkoła
          </StyledIconBlock>
        </div>
      </IconBlocksWrapper>
    </StyledWrapper>
  );
};

export default AboutEstate;
