import React, { FC } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { GOOGLE_MAP_STYLE } from '../../../config/config';
import markerSrc from '../../../assets/svg/marker.svg';

const StyledMarker = styled.img<{ lat: number; lng: number }>`
  transform: translate(-50%, -50%);
  width: 60px;
  color: #1f1f1f;
`;

const GoogleMap: FC<{
  center: { lat: number; lng: number };
  zoom: number;
  className?: string;
  bootstrapURLKeys?: { key: string };
}> = ({ className, center, zoom, bootstrapURLKeys }) => {
  return (
    <div className={className}>
      {/*<GoogleMapReact*/}
      {/*  bootstrapURLKeys={bootstrapURLKeys}*/}
      {/*  defaultZoom={zoom}*/}
      {/*  defaultCenter={center}*/}
      {/*  options={{*/}
      {/*    styles: GOOGLE_MAP_STYLE,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <StyledMarker*/}
      {/*    lat={center.lat}*/}
      {/*    lng={center.lng}*/}
      {/*    src={markerSrc}*/}
      {/*    alt=""*/}
      {/*  />*/}
      {/*</GoogleMapReact>*/}
      <iframe src="https://maps.google.com/maps?q=52.1269,21.0126&hl=pl;z=14&amp;output=embed" />
    </div>
  );
};

export default GoogleMap;
