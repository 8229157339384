import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 200px;
  line-height: 1.5;
  padding: 15px;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in-out, transform 0.4s ease-in-out;

  :hover {
    box-shadow: 0 0 24px 0 #00000012;
    transform: translateY(-5%);
  }

  svg {
    font-size: 6rem;
    color: #000;
    margin-bottom: 15px;
  }
`;

const IconBlock: FC<{ icon: any; className?: string }> = ({
  icon,
  children,
  className,
  ...props
}) => {
  return (
    <StyledWrapper className={className} {...props}>
      <Icon icon={icon} />
      {children}
    </StyledWrapper>
  );
};

export default IconBlock;
