import React, { FC } from 'react';
import styled, { css } from 'styled-components';

type Availability = 'available' | 'unavailable' | 'reserved';

const StyledWrapper = styled.div`
  display: flex;
  font-size: 1.6rem;
  align-items: center;

  @media (max-width: 390px) {
    font-size: 1.4rem;
  }
`;

const StyledCircle = styled.span<{ $availability: Availability }>`
  width: 26px;
  height: 26px;
  margin-right: 15px;
  border-radius: 50%;

  ${({ $availability }) =>
    $availability === 'available'
      ? css`
          background: #4ca800;
        `
      : $availability === 'reserved'
      ? css`
          background: #cc8500;
        `
      : css`
          background: #e60000;
        `};

  @media (max-width: 390px) {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;

const Legend: FC<{ availability: Availability; className?: string }> = ({
  availability,
  className,
  ...props
}) => {
  return (
    <StyledWrapper className={className} {...props}>
      <StyledCircle $availability={availability} />
      {availability === 'available' && 'Dostępne'}
      {availability === 'reserved' && 'Zarezerwowane'}
      {availability === 'unavailable' && 'Sprzedane'}
    </StyledWrapper>
  );
};

export default Legend;
