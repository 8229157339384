import * as React from 'react';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ImageDataLike } from 'gatsby-plugin-image';
import gsap from 'gsap';
import Logo from '../../atoms/Logo/Logo';
import Text from '../../atoms/Text/Text';
import { Link } from 'gatsby';
import videoSrc from '../../../assets/video/hero.mp4';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh) * 100);
  overflow: hidden;
  position: relative;
`;

const StyledVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgb(8, 24, 8);
`;

const StyledTextWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  max-width: 921px;
  padding: 80px 100px;
  background: rgba(0, 0, 0, 0.4);

  h1 {
    font-weight: 700;
    font-size: 8.4rem;
    margin: 0;
    display: flex;
    flex-direction: column;

    > span {
      position: relative;
      overflow: hidden;
      display: flex;

      > span {
        transform: translateY(100%);
      }
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: unset;
    padding: 40px 0;

    h1 {
      align-items: center;
      font-size: 6.4rem;
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 4.4rem;
    }
  }

  @media (max-width: 435px) {
    h1 {
      font-size: 3.4rem;
    }
  }

  @media (max-width: 345px) {
    h1 {
      font-size: 2.8rem;
    }
  }
`;

const StyledLogoWrapper = styled.div`
  position: absolute;
  z-index: 999999;
  background: #fff;
  width: 144px;
  height: 100px;
  top: 25px;
  left: 75px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 5%;
    height: 60px;
    width: 100px;
  }

  @media (max-width: 390px) {
    height: 50px;
    width: 80px;
  }
`;

const StyledLogo = styled(Logo)`
  width: 96px;
  height: auto;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 70px;
  }

  @media (max-width: 390px) {
    width: 50px;
  }
`;

const Hero: FC<{ image: ImageDataLike }> = ({ image }) => {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.to('h1 > span > span', {
      stagger: 0.03,
      duration: 2,
      y: 0,
      ease: 'expo.out',
    });
  }, []);

  return (
    <StyledWrapper>
      <StyledLogoWrapper>
        <Link to="/" aria-label="Strona główna">
          <StyledLogo />
        </Link>
      </StyledLogoWrapper>

      <StyledVideo
        src={videoSrc}
        autoPlay
        muted
        loop
        disablePictureInPicture
        preload="none"
      />

      <StyledTextWrapper>
        <h1>
          <Text>Witaj na osiedlu</Text>
          <Text>Nowa Farma</Text>
        </h1>
      </StyledTextWrapper>
    </StyledWrapper>
  );
};

export default Hero;
