import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../../atoms/Input/Input';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import {
  arrowInCircleIcon,
  emailIcon,
  markerIcon,
  phoneIcon,
} from '../../../assets/icons';
import Icon from '@iconify/react';
import { EMAIL, PHONE_NUMBER } from '../../../config/config';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import { formatAddress } from '../../../utils/formatAddress';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;

  @media (max-width: 420px) {
    margin-top: 80px;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 580px;
  margin-bottom: 80px;

  h2 {
    margin: 0;
    font-size: 3.4rem;
  }

  p {
    margin: 0 0 15px;
    font-size: 2rem;
  }

  @media (max-width: 1024px) {
    margin: 0 auto 40px auto;
  }

  @media (max-width: 420px) {
    p {
      font-size: 1.6rem;
      margin: 0 0 10px;
    }

    h2 {
      font-size: 2.8rem;
    }
  }
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 50px;
      color: #000;
      text-decoration: none;

      svg {
        font-size: 5.2rem;
        margin-bottom: 20px;
      }

      :last-of-type {
        margin-bottom: 0;

        svg {
          font-size: 6rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    > div:last-of-type {
      margin-top: 90px;
    }
  }

  @media (max-width: 420px) {
    > div:last-of-type {
      margin-top: 50px;
      a {
        margin-bottom: 40px;
        font-size: 1.6rem;
        svg {
          font-size: 4rem;
        }

        :last-of-type {
          svg {
            font-size: 4.8rem;
          }
        }
      }
    }
  }
`;

const StyledForm = styled.form`
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInput = styled(Input)`
  width: 580px;
  margin-bottom: 40px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 500px;
  }

  @media (max-width: 420px) {
    margin-bottom: 30px;
  }
`;

const StyledReCAPTCHAWrapper = styled.div`
  margin-top: 40px;
  position: relative;

  span {
    font-size: 1.2rem;
    color: #e60000;
    margin-top: 10px;
    display: block;
  }

  @media (max-width: 1024px) {
    margin-right: auto;
  }

  @media (max-width: 340px) {
    transform: scale(0.8);
    transform-origin: left top;
  } ;
`;

const StyledBottomFormWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  width: 300px;

  div:last-of-type {
    font-size: 1rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 400px;
    margin-right: auto;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  background: transparent;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  :hover {
    color: #737373;
  }

  :active {
    transform: scale(0.9);
  }

  svg {
    font-size: 4.8rem;
    margin-left: 5px;
  }

  @media (max-width: 1024px) {
    margin: 30px auto 0 auto;
  }
`;

const StyledAfterSendMessage = styled.div<{ $success: boolean }>`
  font-size: 1.8rem;
  color: ${({ $success }) => ($success ? '#398000' : '#e60000')};
`;

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [wasSendTry, setWasSendTry] = useState<boolean>(false);
  const [successfulSend, setSuccessfulSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);
  const {
    datoCmsSetting,
  }: {
    datoCmsSetting: {
      mail: string;
      phoneNumber: string;
      address: string;
      googleMaps: string;
    };
  } = useStaticQuery(query);

  const onSubmit = (data: {
    name: string;
    phone?: string;
    email: string;
    message: string;
    consent: boolean;
  }) => {
    if (!captchaKey) {
      setWasSendTry(true);
      return;
    }

    axios
      .post(`://formsubmit.co/ajax/${datoCmsSetting.mail}`, {
        'Imię i nazwisko': data.name,
        'Numer telefonu': data.phone || 'Nie podano',
        'E-mail': data.email,
        Wiadomość: data.message,
      })
      .then((res) =>
        res.status === 200 ? setSuccessfulSend(true) : setErrorSend(true)
      )
      .catch(() => setErrorSend(true));
  };

  useEffect(() => {
    if (
      (errors.message || errors.name || errors.email || errors.consent) &&
      !captchaKey
    ) {
      setWasSendTry(true);
    }
  }, [errors.message, errors.name, errors.email, errors.consent]);

  return (
    <StyledWrapper id="contact">
      <StyledHeader>
        <h2
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Skontaktuj się z nami!
        </h2>
        <p
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Masz jakieś pytania?
        </p>
      </StyledHeader>

      <StyledInnerWrapper>
        {!successfulSend && !errorSend ? (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledInput
              label="Imię i nazwisko"
              error={errors.name ? 'Imię i nazwisko jest wymagane.' : undefined}
              {...register('name', { required: true })}
            />
            <StyledInput
              label="E-mail"
              error={
                errors.email?.type === 'required'
                  ? 'E-mail jest wymagany.'
                  : errors.email?.type === 'pattern'
                  ? 'E-mail jest nieprawidłowy.'
                  : undefined
              }
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            <StyledInput label="Numer telefonu" {...register('phone')} />
            <StyledInput
              label="Wiadomość"
              error={errors.message ? 'Napisz wiadomość.' : undefined}
              {...register('message', { required: true })}
            />

            <StyledReCAPTCHAWrapper>
              <ReCAPTCHA
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
                onChange={(key) => {
                  setCaptchaKey(key);
                  setWasSendTry(false);
                }}
                hl="pl"
              />
              {!captchaKey && wasSendTry && (
                <span>Potwierdź, że nie jesteś robotem</span>
              )}
            </StyledReCAPTCHAWrapper>

            <StyledBottomFormWrapper>
              <StyledCheckbox
                label={`Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania`}
                error={errors.consent ? 'Zgoda jest wymagana.' : undefined}
                {...register('consent', { required: true })}
              />
              <StyledButton type="submit">
                Wyślij
                <Icon icon={arrowInCircleIcon} />
              </StyledButton>
            </StyledBottomFormWrapper>
          </StyledForm>
        ) : (
          <StyledAfterSendMessage $success={successfulSend}>
            {successfulSend
              ? 'Twoja wiadomość została wysłana pomyślnie.'
              : errorSend &&
                'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.'}
          </StyledAfterSendMessage>
        )}

        <div>
          <a
            href={`tel:+48${datoCmsSetting.phoneNumber}`}
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
            rel="noopener"
          >
            <Icon icon={phoneIcon} />
            tel. {formatPhoneNumber(datoCmsSetting.phoneNumber)}
          </a>
          <a
            href={`mailto:${datoCmsSetting.mail}`}
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
            rel="noopener"
          >
            <Icon icon={emailIcon} />
            {datoCmsSetting.mail}
          </a>
          <a
            href={datoCmsSetting.googleMaps}
            target="_blank"
            data-sal="slide-up"
            data-sal-easing="ease-in-out-quart"
            data-sal-duration="500"
            rel="noopener"
          >
            <Icon icon={markerIcon} />
            <div
              dangerouslySetInnerHTML={{
                __html: formatAddress(datoCmsSetting.address),
              }}
            />
          </a>
        </div>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      phoneNumber
      mail
      address
      googleMaps
    }
  }
`;

export default Contact;
