import React from 'react';
import styled from 'styled-components';
import Logo from '../../atoms/Logo/Logo';
import { NAVIGATION_ITEMS } from '../../../config/config';
import Icon from '@iconify/react';
import facebookIcon from '@iconify/icons-dashicons/facebook';
import instagramIcon from '@iconify/icons-dashicons/instagram';
import { graphql, useStaticQuery } from 'gatsby';

const StyledFooter = styled.footer`
  width: 90%;
  max-width: 1450px;
  margin: 120px auto 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 60px auto 40px;
  }

  @media (max-width: 420px) {
    margin: 20px auto 40px;
  }
`;

const StyledLeftColumn = styled.div`
  img {
    width: 216px;
    height: auto;
  }

  p {
    font-size: 1.2rem;
    color: #ccc;
    margin-top: 55px;
    max-width: 390px;

    @media (max-width: 1220px) {
      max-width: 200px;
      font-size: 1rem;
    }
  }

  @media (max-width: 1024px) {
    p {
      display: none;
    }
  }

  @media (max-width: 420px) {
    img {
      width: 140px;
    }
  }
`;

const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ul {
    display: flex;
    list-style-type: none;
    border-bottom: 1px solid #ccc;
    padding: 0 0 25px;
    margin-bottom: 30px;
  }

  li {
    font-size: 1.6rem;
    margin-right: 40px;

    :last-of-type {
      margin-right: 0;
    }

    a {
      color: inherit;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1024px) {
    align-items: center;

    ul {
      flex-wrap: wrap;
      margin-top: 5px;
      justify-content: center;
    }

    li {
      margin: 20px 10px 0 20px;
    }
  }
`;

const StyledCopyright = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin-bottom: 15px;

    a {
      font-weight: 700;
      color: inherit;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 26px;
    align-items: center;
  }
`;

const StyledRightBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;

    span {
      display: none;
    }
  }
`;

const StyledSocialMedia = styled.a`
  :first-of-type {
    margin-left: 12px;
    margin-right: 5px;
  }

  color: #1f1f1f;
  text-decoration: none;

  svg {
    font-size: 2.4rem;
  }

  @media (max-width: 1024px) {
    svg {
      font-size: 2.8rem;
    }
  }
`;

const Footer = () => {
  const {
    datoCmsSetting,
  }: { datoCmsSetting: { facebook: string; instagram: string } } =
    useStaticQuery(query);

  return (
    <StyledFooter>
      <StyledLeftColumn>
        <Logo />
        <p>
          Treści znajdujące się na stronie internetowej nie stanowią oferty w
          rozumieniu przepisów prawa i winny być interpretowane wyłącznie jak
          informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
        </p>
      </StyledLeftColumn>
      <StyledRightColumn>
        <ul>
          {NAVIGATION_ITEMS.map(({ href, name }) => (
            <li key={href}>
              <a href={href} data-scroll="" rel="noopener">
                {name}
              </a>
            </li>
          ))}
        </ul>
        <StyledRightBottomWrapper>
          <StyledSocialMediaWrapper>
            <span> Obserwuj nas:</span>
            <StyledSocialMedia
              href={datoCmsSetting.facebook}
              aria-label="Facebook"
            >
              <Icon icon={facebookIcon} />
            </StyledSocialMedia>
            <StyledSocialMedia
              href={datoCmsSetting.instagram}
              aria-label="Instagram"
            >
              <Icon icon={instagramIcon} />
            </StyledSocialMedia>
          </StyledSocialMediaWrapper>

          <StyledCopyright>
            <span>
              Stworzone z ❤️ przez{' '}
              <a rel="noopener" href="https://rend.pro/offer/investment-website/">
                RendPro
              </a>
            </span>
            <span>2021 © Nowa Farma.</span>
          </StyledCopyright>
        </StyledRightBottomWrapper>
      </StyledRightColumn>
    </StyledFooter>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      instagram
      facebook
    }
  }
`;

export default Footer;
