import React, { FC } from 'react';
import ReactSpringLightbox, { ImagesListType } from 'react-spring-lightbox';
import styled from 'styled-components';
import closeIcon from '@iconify/icons-bi/x-lg';
import Icon from '@iconify/react';
import { useButtonEffects } from '../../../hooks/useButtonEffects';
import { arrowLeftIcon, arrowRightIcon } from '../../../assets/icons';
import { rippleAnimation } from '../../../theme/repeatedStyles';

const StyledReactSpringLightbox = styled(ReactSpringLightbox)`
  z-index: 214748363 !important;
  background: rgba(0, 0, 0, 0.5);

  .lightbox-image-pager {
    cursor: grab;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  color: #fff;

  div {
    font-size: 2.2rem;
    font-weight: 700;
  }

  button {
    width: 40px;
    color: #fff;
    cursor: pointer;
    height: 40px;
    background: transparent;
    font-size: 2.2rem;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease-in-out;

    :hover {
      color: #d2d2d2;
    }
  }
`;

const StyledArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  background: transparent;
  transition: background 0.2s ease-in-out, transform 0.2s ease-out;
  transform-origin: center center;
  overflow: hidden;

  :first-of-type {
    left: 20px;
  }

  :last-of-type {
    right: 20px;
  }

  :hover {
    background: #000;
  }

  :active {
    transform: translateY(-50%) scale(0.9);
  }

  .ripple {
    width: 120px;
    height: 120px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }
`;

const Header: FC<{
  length: number;
  currentIndex: number;
  onClose: () => void;
}> = ({ length, currentIndex, onClose }) => (
  <StyledHeader>
    <div>
      {currentIndex + 1} / {length}
    </div>
    <button aria-label="Zamknij" onClick={onClose}>
      <Icon icon={closeIcon} />
    </button>
  </StyledHeader>
);

const ArrowButton: FC<React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  const { onMouseDown } = useButtonEffects();

  return (
    <StyledArrowButton {...(props as any)} onMouseDown={onMouseDown}>
      {children}
    </StyledArrowButton>
  );
};

const Lightbox: FC<{
  currentImageIndex: number;
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  images: ImagesListType;
  slideTo?: (index: number, speed?: number, runCallbacks?: boolean) => void;
}> = ({
  currentImageIndex,
  setCurrentImageIndex,
  isOpen,
  setOpen,
  images,
  slideTo,
}) => {
  const onPrev = () =>
    setCurrentImageIndex((prevState) =>
      currentImageIndex === 0 ? images.length - 1 : prevState - 1
    );

  const onNext = () =>
    setCurrentImageIndex((prevState) =>
      currentImageIndex === images.length - 1 ? 0 : prevState + 1
    );

  const onClose = () => {
    slideTo(currentImageIndex);
    setOpen(false);
  };

  return (
    <StyledReactSpringLightbox
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      onNext={onNext}
      onPrev={onPrev}
      images={images}
      currentIndex={currentImageIndex}
      renderHeader={() => (
        <Header
          length={images.length}
          currentIndex={currentImageIndex}
          onClose={onClose}
        />
      )}
      renderNextButton={() => (
        <ArrowButton onClick={onNext} aria-label="Następne zdjęcie">
          <Icon icon={arrowRightIcon} />
        </ArrowButton>
      )}
      renderPrevButton={() => (
        <ArrowButton onClick={onPrev} aria-label="Poprzednie zdjęcie">
          <Icon icon={arrowLeftIcon} />
        </ArrowButton>
      )}
    />
  );
};

export default Lightbox;
