import React, { FC } from 'react';
import styled from 'styled-components';
import { decorationLine } from '../../../theme/repeatedStyles';
import Slider from '../../molecules/Slider/Slider';
import { filterLighboxImageDatoCMS } from '../../../utils/filterImages';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

const StyledWrapper = styled.section`
  width: 90%;
  margin-top: 260px;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    margin-top: 80px;
    max-width: 700px;
  }
`;

const StyledHeader = styled.header`
  ${decorationLine};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 35px;

  h2 {
    margin: 0;
    font-size: 3.4rem;
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
    }
  }
`;

const BuildGallery: FC<{
  images: ImageDataLike[];
  lightboxImages: ImageDataLike[];
}> = ({ images, lightboxImages }) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        <h2
          data-sal="slide-right"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Zdjęcia z budowy
        </h2>
      </StyledHeader>
      <Slider
        images={images}
        lightboxImages={filterLighboxImageDatoCMS(lightboxImages)}
        navigation="top"
      />
    </StyledWrapper>
  );
};

export default BuildGallery;
