import { useState, MouseEvent } from 'react';

export const useInteractiveVisualization = () => {
  const [isBoxActive, setBoxActive] = useState(false);
  const [currentActive, setCurrentActive] = useState<'A' | 'B' | 'C' | 'D'>(
    'A'
  );
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout>(null);

  const onMouseEnter = (e: MouseEvent<SVGElement>) => {
    if (delayHandler) {
      clearTimeout(delayHandler);
      setDelayHandler(null);
    }
    setBoxActive(true);

    setCurrentActive(
      (e.currentTarget as SVGElement).id.split('_')[1] as unknown as any
    );
  };

  const onMouseLeave = (e: MouseEvent<SVGElement>) => {
    const { relatedTarget } = e as any;

    if (
      (relatedTarget instanceof HTMLElement ||
        relatedTarget instanceof SVGElement) &&
      (relatedTarget.classList.contains('box') ||
        relatedTarget.nodeName === 'path' ||
        relatedTarget.nodeName === 'g' ||
        relatedTarget.nodeName === 'A')
    ) {
      return;
    }

    setDelayHandler(
      setTimeout(() => {
        setBoxActive(false);
      }, 300)
    );
  };

  return {
    isBoxActive,
    setBoxActive,
    currentActive,
    delayHandler,
    setDelayHandler,
    onMouseLeave,
    onMouseEnter,
  };
};
