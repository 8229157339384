import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Legend from './Legend/Legend';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import InteractiveVisualization from './InteractiveVisualization/InteractiveVisualization';

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;

  @media (max-width: 1024px) {
    margin-top: 120px;
    max-width: 700px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 65px;

  h2 {
    font-size: 3.4rem;
    text-align: center;
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 2.8rem;
      margin: 0 0 30px;
    }
  }
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const StyledLegendWrapper = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledLegend = styled(Legend)`
  margin-right: 45px;

  :last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-top: 20px;

    :first-of-type {
      margin: 0;
    }
  }

  @media (max-width: 3900px) {
    margin-top: 10px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: auto;

  @media (max-width: 460px) {
    width: 140px;
  }

  @media (max-width: 390px) {
    font-size: 1.4rem;
    width: 110px;

    div:first-of-type {
      margin-right: 10px;
      span {
        width: 18px;
        height: 18px;
      }
    }
  }
`;

const InteractiveVisualizationSection = () => {
  const [isOnlyAvailable, setOnlyAvailable] = useState<boolean>(false);
  const [animating, setAnimating] = useState<boolean>(false);
  const tl = gsap.timeline();

  const resetAnimating = () => setAnimating(false);

  useEffect(() => {
    if (isOnlyAvailable) {
      setAnimating(true);
      tl.to(
        '.path_unavailable, .path_reserved, .indication_unavailable, .indication_reserved',
        {
          pointerEvents: 'none',
          duration: 0,
          delay: 0,
        }
      )
        .to('.legend-unavailable, .legend-reserved', {
          duration: 0.5,
          ease: 'power4.out',
          stagger: 0.2,
          scale: 0.8,
          opacity: 0,
          pointerEvents: 'none',
          y: 10,
        })
        .to('.indication_unavailable, .indication_reserved', {
          duration: 0.5,
          stagger: 0.2,
          scale: 0.8,
          opacity: 0,
          delay: -0.5,
          ease: 'power4.out',
          onComplete: resetAnimating,
        });
    } else {
      setAnimating(true);
      tl.to(
        '.path_unavailable, .path_reserved, .indication_unavailable, .indication_reserved',
        {
          pointerEvents: 'auto',
          duration: 0,
          delay: 0,
        }
      )
        .to('.legend-unavailable, .legend-reserved', {
          duration: 0.5,
          ease: 'power4.out',
          stagger: 0.2,
          scale: 1,
          reversed: true,
          opacity: 1,
          pointerEvents: 'auto',
          y: 0,
        })
        .to('.indication_unavailable, .indication_reserved', {
          duration: 0.5,
          stagger: 0.2,
          reversed: true,
          scale: 1,
          opacity: 1,
          delay: -0.5,
          ease: 'power4.out',
          onComplete: resetAnimating,
        });
    }
  }, [isOnlyAvailable]);

  return (
    <StyledWrapper id="houses">
      <StyledHeader>
        <h2
          data-sal="slide-up"
          data-sal-easing="ease-in-out-quart"
          data-sal-duration="500"
        >
          Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty.
        </h2>
      </StyledHeader>
      <div
        data-sal="zoom-out"
        data-sal-easing="ease-in-out-quart"
        data-sal-duration="1000"
      >
        <InteractiveVisualization />
      </div>
      <StyledBottomWrapper>
        <StyledLegendWrapper>
          <StyledLegend availability="available" className="legend-available" />
          <StyledLegend availability="reserved" className="legend-reserved" />
          <StyledLegend
            availability="unavailable"
            className="legend-unavailable"
          />
        </StyledLegendWrapper>
        <StyledCheckbox
          label="Pokaż tylko dostepne"
          onChange={() => setOnlyAvailable((prevState) => !prevState)}
          checked={isOnlyAvailable}
        />
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};

export default InteractiveVisualizationSection;
