import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import InteractiveVisualizationMap from '../../../../assets/svg/interactive_visualization_map';
import { Availability } from '../../../../types/availability';
import Box from '../Box/Box';
import { useInteractiveVisualization } from '../useInteractiveVisualization';
import { getColor } from '../../../../utils/getColor';
import { getClassNameWithAvailability } from '../../../../utils/getClassNameWithAvailability';
import { HouseInformation } from '../types/houseInformation';

const StyledWrapper = styled.div`
  position: relative;

  > svg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    g.parcel {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;

      &.is-active {
        opacity: 0.4;
      }
    }

    #path_A,
    #path_B,
    #path_C,
    #path_D {
      cursor: pointer;
    }

    #path_C:hover ~ #C,
    #path_D:hover ~ #D,
    #path_B:hover ~ #B,
    #path_A:hover ~ #A {
      opacity: 0.4;
    }
  }
`;

const StyledImage = styled(GatsbyImage)``;

const StyledIndicationsWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const StyledIndicationRow = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  z-index: 20;

  :first-of-type {
    left: 30%;
    top: 28%;
    width: 15%;
    transform: rotate(-15deg);
  }

  :last-of-type {
    left: 55%;
    top: 35%;
    transform: rotate(-29deg);
    width: 11%;
  }

  @media (max-width: 1024px) {
    :first-of-type {
      width: 17%;
    }

    :last-of-type {
      width: 14%;
    }
  }

  @media (max-width: 665px) {
    :last-of-type {
      width: 16%;
    }
  }
`;

const StyledIndication = styled(Link)<{ $availability: Availability }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  background: ${({ $availability }) => getColor($availability)};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  transform: rotate(15deg);

  ${StyledIndicationRow}:last-of-type & {
    transform: rotate(29deg);
  }

  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    font-size: 1.8rem;
  }

  @media (max-width: 435px) {
    width: 25px;
    height: 25px;
    font-size: 1.6rem;
  }

  @media (max-width: 375px) {
    width: 20px;
    height: 20px;
    font-size: 1.4rem;
  }
`;

const getHouse = (id: 'A' | 'B' | 'C' | 'D', houses: HouseInformation[]) => {
  switch (id) {
    case 'A':
      return houses[0];
    case 'B':
      return houses[1];
    case 'C':
      return houses[2];
    case 'D':
      return houses[3];
  }
};

const InteractiveVisualization: FC = () => {
  const data: {
    file: { childImageSharp: ImageDataLike };
    allDatoCmsHouse: { nodes: HouseInformation[] };
  } = useStaticQuery(query);
  const {
    setBoxActive,
    isBoxActive,
    currentActive,
    setDelayHandler,
    delayHandler,
    onMouseLeave,
    onMouseEnter,
  } = useInteractiveVisualization();

  const houses = data.allDatoCmsHouse.nodes;

  return (
    <StyledWrapper>
      <StyledImage image={getImage(data.file.childImageSharp)} alt="" />
      <StyledIndicationsWrapper>
        <StyledIndicationRow>
          <StyledIndication
            to="/lokal/A"
            $availability={houses[0].availability}
            id="indication_A"
            onMouseEnter={onMouseEnter as any}
            onMouseLeave={onMouseLeave as any}
            className={getClassNameWithAvailability(
              'indication',
              houses[0].availability
            )}
          >
            A
          </StyledIndication>
          <StyledIndication
            to="/lokal/B"
            $availability={houses[1].availability}
            id="indication_B"
            onMouseEnter={onMouseEnter as any}
            onMouseLeave={onMouseLeave as any}
            className={getClassNameWithAvailability(
              'indication',
              houses[1].availability
            )}
          >
            B
          </StyledIndication>
        </StyledIndicationRow>

        <StyledIndicationRow>
          <StyledIndication
            to="/lokal/C"
            $availability={houses[2].availability}
            id="indication_C"
            onMouseEnter={onMouseEnter as any}
            onMouseLeave={onMouseLeave as any}
            className={getClassNameWithAvailability(
              'indication',
              houses[2].availability
            )}
          >
            C
          </StyledIndication>
          <StyledIndication
            to="/lokal/D"
            $availability={houses[3].availability}
            id="indication_D"
            onMouseEnter={onMouseEnter as any}
            onMouseLeave={onMouseLeave as any}
            className={getClassNameWithAvailability(
              'indication',
              houses[3].availability
            )}
          >
            D
          </StyledIndication>
        </StyledIndicationRow>
      </StyledIndicationsWrapper>
      <InteractiveVisualizationMap
        A={houses[0].availability}
        B={houses[1].availability}
        C={houses[2].availability}
        D={houses[3].availability}
        isBoxActive={isBoxActive}
        currentActive={currentActive}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Box
        currentHouse={getHouse(currentActive, houses)}
        isActive={isBoxActive}
        setIsActive={setBoxActive}
        delayHandler={delayHandler}
        setDelayHandler={setDelayHandler}
      />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "interactive_visualization" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    allDatoCmsHouse(sort: { fields: number, order: ASC }) {
      nodes {
        number
        availability
        area
        deliveryDate
        garage
        parcelArea
        roomsCount
        firstFloor {
          area
          name
        }
        secondFloor {
          area
          name
        }
      }
    }
  }
`;

export default InteractiveVisualization;
